// import fetch from 'node-fetch';

// utility function - fetchProviderIdWithEntra
export const fetchProviderIdWithEntra = async (sub, accessToken) => {
    let apiEndpoint = `${process.env.REACT_APP_PROVIDER_BY_ENTRA_BASE_URL}`;
    const entraCodeUrl = `${apiEndpoint}${sub}`;

    const res = await fetch(entraCodeUrl, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!res.ok) {
      throw new Error('Provider ID not found');
    }
    let pid = await res.text();
    return pid; // Get the provider ID
  };

  // utility function - fetchProviderOrganization
  export const fetchProviderOrganization = async (providerId, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_PROVIDERS_API_BASE_URL;
    const providerControllerURL = `${apiEndpoint}${providerId}`;

    const res3 = await fetch(providerControllerURL, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!res3.ok) {
      throw new Error('Failed to fetch provider organization');
    }

    let po = await res3.json();
    return {
      name: po.organization.name,
      id: po.organization.organizationId
    }; // Get the organization map
  };

  // utility function - fetchGroupId
  export const fetchGroupId = async (providerId, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_PROVIDERS_API_BASE_URL;
    const providerControllerURL = `${apiEndpoint}${providerId}`;

    const res2 = await fetch(providerControllerURL, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!res2.ok) {
      throw new Error('Failed to fetch group ID');
    }

    let pg = await res2.json();
    return {
      name: pg.organization.organizationGroup.name,
      id: pg.organization.organizationGroup.organizationGroupId
    }; // Get the group map
  };

  // utility function - fetchGroupId
  export const fetchGroupPlans = async (groupId, organizationId, providerId, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_OUTCOME_SERVICE_BASE_URL;
    const outcomePlansURL = `${apiEndpoint}?organizationGroupId=${groupId}&organizationId=${organizationId}&providerId=${providerId}`;

    const res2 = await fetch(outcomePlansURL, {
      method: 'GET',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (!res2.ok) {
      throw new Error('Failed to fetch group plans');
    }

    let planJson = await res2.json();
    if (!planJson) {
      throw new Error('No plans found');
    }

    let plans = planJson.outcomePlans;
    let planDetails = [];
    for (let i = 0; i < plans.length; i++) {
      let { aptType, minAppts, maxAppts } = fetchPlanDetails(plans[i]);
      planDetails.push({ name: plans[i].name, id: plans[i].outcomePlanId, aptType: aptType, minAppts: minAppts, maxAppts: maxAppts });
    }
    return planDetails; // return plan details
  };

  // utility function - fetchPlanDetails
  // Warning: this function is built using the current (11/2024)
  // structure of the outcome plan object. If the structure changes,
  // this function will need to be updated. Also, this function assumes
  // that there is only one appointment type in the plan. If there are
  // multiple appointment types, this function will need to be updated.
  export function fetchPlanDetails(plan) {
    if (plan.plans) {
      if (plan.plans.APPOINTMENT.type === 'APPOINTMENT') {
        const planElements = plan.plans.APPOINTMENT.planElements;
        const aptType = Object.keys(planElements)[0];
        const minAppts = Object.values(planElements)[0].limit.min;
        const maxAppts = Object.values(planElements)[0].limit.max;
        return { aptType, minAppts, maxAppts };
      }
    }
    return '';
  };

  // utility function - submitFormData
  export const submitFormData = async (requestObject, accessToken) => {
    let apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestObject),
    });

    if (!response.ok) {
      throw new Error('Failed to submit form data');
    }

    const responseData = await response.json();
    alert('Case created with the number ' + responseData.id);
  };
